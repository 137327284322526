import React from "react";
import "./ImageAnimeL.css";
import image4 from "./ASAFADAMEMES/image4.webp";
import image5 from "./ASAFADAMEMES/image5.png";
import image6 from "./ASAFADAMEMES/image6.png";

function ImageAnime() {
  const images = [image4, image5, image6];

  return (
    <div className="animation-wrapper">
      <div className="animation-container">
        {images.map((imgSrc, index) => (
          <img key={index} src={imgSrc} className={`animated-image img${index + 1}`} alt={`img-${index + 1}`} />
        ))}
      </div>
    </div>
  );
}

export default ImageAnime;
