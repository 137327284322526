import React from "react";
import "./ImageAnime.css";
import image1 from "./ASAFADAMEMES/image1.webp";
import image2 from "./ASAFADAMEMES/image2.webp";
import image3 from "./ASAFADAMEMES/image3.webp";

function ImageAnime() {
  const images = [image1, image2, image3];

  return (
    <div className="env-img">
      <div className="container">
        {images.map((imgSrc, index) => (
          <img key={index} src={imgSrc} className={`image img${index + 1}`} alt={`img-${index + 1}`} />
        ))}
      </div>
    </div>
  );
}

export default ImageAnime;
