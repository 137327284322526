import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./App.css";
import Animate from "./Animate";
import Page from './Page';
import Background from './Background';
import dinl from "./l.png";
import dinr from "./r.png";
import ImageAnime from "./ImageAnime";
import ImageAnimeL from "./ImageAnimeL.jsx";

function App() {
  const [tokenData, setTokenData] = useState(null);
  const accessKey = 'A1qQaAA9kdfnn4Mmn44bpoieIYHKkdghFKUD1978563llakLLLKdfslphgarcorc3haeogmmMNn243wf';

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await axios.get('https://interca.onrender.com/api/purchaseData', {
          headers: {
            'x-access-key': accessKey,
          },
        });
        setTokenData(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados do token:', error);
      }
    };

    fetchTokenData();
  }, []);

  return (
    <div className='App'>
      <Background />
      <Page />
      <Animate />
      <img className='img l' src={dinl} alt="" />
      <img className='img r' src={dinr} alt="" />
      <ImageAnime />
      <ImageAnimeL />
      <div className="env-tokenName">
        <div className="tokenName">
          {tokenData ? tokenData.tokenName : 'Carregando...'}
        </div>
        <div className="text">
          You can move this girl!
        </div>
      </div>
      <div className="crt" />
    </div>
  );
}

export default App;
